import React, { useState, useRef, useEffect } from "react";

const Dropdown = ({
    isOpen,
    onToggle,
    title,
    contentClasses,
    placholder,
    children,
}) => {
    const [maxHeight, setMaxHeight] = useState("0px");
    const contentRef = useRef(null);

    useEffect(() => {
        if (isOpen) {
            setMaxHeight(`${contentRef.current.scrollHeight + 37}px`);
        } else {
            setMaxHeight("0px");
        }
    }, [isOpen]);

    return (
        <div
            className={`booking__dropdown dropdown ${
                isOpen ? "dropdown_active" : ""
            }`}
        >
            <div
                className="dropdown__header booking__dropdown-header"
                onClick={onToggle}
            >
                <p className="booking__dropdown-text">{title}</p>
                <p className="booking__dropdown-placeholder">{placholder}</p>
                <svg>
                    <use href="#dropdownArrow"></use>
                </svg>
            </div>
            <div
                ref={contentRef}
                className="dropdown__body booking__dropdown-body"
                style={{ maxHeight }}
            >
                <div className={`booking__dropdown-content ${contentClasses}`}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Dropdown;
