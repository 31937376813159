import React, { useCallback, useEffect, useRef, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";

import Dropdown from "./Dropdown";

registerLocale("ru", ru);

function getNumberWithNoun(number, arrOfEndings) {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
        return `${number} ${arrOfEndings[2]}`;
    }
    n %= 10;
    if (n === 1) {
        return `${number} ${arrOfEndings[0]}`;
    }
    if (n >= 2 && n <= 4) {
        return `${number} ${arrOfEndings[1]}`;
    }
    return `${number} ${arrOfEndings[2]}`;
}

function formatDate(date) {
    return new Intl.DateTimeFormat("ru-RU", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    }).format(date);
}

const BookingForm = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    const [openDropdown, setOpenDropdown] = useState(null);
    const [numberOfAdults, setNumberOfAdults] = useState(1);
    const [numberOfChildren, setNumberOfChildren] = useState(0);
    const [dateRange, setDateRange] = useState([today, tomorrow]);

    const [startDate, endDate] = dateRange;

    const dropdownRef = useRef(null);

    const handleOutsideClick = useCallback((event) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target) &&
            !event.target.classList.contains(
                "react-datepicker__day--outside-month"
            )
        ) {
            setOpenDropdown(null);
        }
    }, []);

    useEffect(() => {
        if (openDropdown !== null) {
            document.addEventListener("click", handleOutsideClick);
        } else {
            document.removeEventListener("click", handleOutsideClick);
        }
        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, [openDropdown, handleOutsideClick]);

    const handleToggle = (dropdownId) => {
        setOpenDropdown(openDropdown === dropdownId ? null : dropdownId);
        if (dropdownId === 2) dateRange[1] = null;
    };

    const increaseAdults = () => {
        setNumberOfAdults(numberOfAdults + 1);
    };

    const decreaseAdults = () => {
        if (numberOfAdults === 1) return;

        setNumberOfAdults(numberOfAdults - 1);
    };

    const increaseChildren = () => {
        setNumberOfChildren(numberOfChildren + 1);
    };

    const decreaseChildren = () => {
        if (numberOfChildren === 0) return;

        setNumberOfChildren(numberOfChildren - 1);
    };

    const handleButtonSubmit = (e) => {
        e.preventDefault();

        if (endDate) {
            window.location.href = `/booking/?startDate=${formatDate(
                startDate
            ).replaceAll(".", "-")}&endDate=${formatDate(endDate).replaceAll(
                ".",
                "-"
            )}&guests=${numberOfAdults + numberOfChildren}`;
        } else {
            setOpenDropdown(2);
        }
    };

    return (
        <form
            className="booking__form"
            onSubmit={handleButtonSubmit}
            ref={dropdownRef}
        >
            <Dropdown
                isOpen={openDropdown === 1}
                onToggle={() => handleToggle(1)}
                title={startDate ? `${formatDate(startDate)}` : ""}
                placholder="Дата заселения"
                contentClasses="booking__dropdown-content_datepicker"
            >
                <DatePicker
                    locale={ru}
                    selected={startDate}
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    minDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Дата"
                    autoComplete="on"
                    name="dates"
                    inline
                    onChange={(update) => {
                        setDateRange(update);
                        update[0] && update[1] && setOpenDropdown(null);
                    }}
                />
            </Dropdown>
            <Dropdown
                isOpen={openDropdown === 2}
                onToggle={() => handleToggle(2)}
                title={endDate ? `${formatDate(endDate)}` : ""}
                placholder="Дата выезда"
                contentClasses="booking__dropdown-content_datepicker"
            >
                <DatePicker
                    locale={ru}
                    selected={startDate}
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    minDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Дата"
                    autoComplete="off"
                    name="dates"
                    inline
                    onChange={(update) => {
                        setDateRange(update);
                        update[0] && update[1] && setOpenDropdown(null);
                    }}
                />
            </Dropdown>
            <Dropdown
                isOpen={openDropdown === 3}
                onToggle={() => handleToggle(3)}
                placholder="Количество гостей"
                title={getNumberWithNoun(numberOfAdults + numberOfChildren, [
                    "гость",
                    "гостя",
                    "гостей",
                ])}
                contentClasses="booking__dropdown-content_counter"
            >
                <div className="booking__counter-wrapper">
                    <p className="booking__counter-title">Взрослые</p>
                    <div className="booking__counter">
                        <button
                            type="button"
                            className="booking__counter-minus"
                            onClick={decreaseAdults}
                            disabled={numberOfAdults === 1}
                        >
                            <svg>
                                <use href="#minus"></use>
                            </svg>
                        </button>
                        <span className="booking__counter-number">
                            {numberOfAdults}
                        </span>
                        <button
                            type="button"
                            className="booking__counter-plus"
                            onClick={increaseAdults}
                        >
                            <svg>
                                <use href="#plus"></use>
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="booking__counter-wrapper">
                    <p className="booking__counter-title">Дети от 14 лет</p>
                    <div className="booking__counter">
                        <button
                            type="button"
                            className="booking__counter-minus"
                            onClick={decreaseChildren}
                            disabled={numberOfChildren === 0}
                        >
                            <svg>
                                <use href="#minus"></use>
                            </svg>
                        </button>
                        <span className="booking__counter-number">
                            {numberOfChildren}
                        </span>
                        <button
                            type="button"
                            className="booking__counter-plus"
                            onClick={increaseChildren}
                        >
                            <svg>
                                <use href="#plus"></use>
                            </svg>
                        </button>
                    </div>
                </div>
            </Dropdown>
            <button
                className="button-main button-main_white button-main_width100 booking__form-submit-btn"
                type="submit"
            >
                Смотреть номера
            </button>
        </form>
    );
};

export default BookingForm;
