import React from "react";
import { createRoot } from "react-dom/client";
import BookingForm from "./BookingForm";

const bookingFormContainer = document.querySelector("#booking-form");
if (bookingFormContainer) {
    const root = createRoot(bookingFormContainer);
    root.render(<BookingForm />);
}

// import React from "react";
// import ReactDOM from "react-dom";
// import BookingForm from "./BookingForm";

// ReactDOM.render(
//     <React.StrictMode>
//         <BookingForm />
//     </React.StrictMode>,
//     document.getElementById("root")
// );
